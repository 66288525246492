import React from "react";
import Box from "./Box";

const Section = ({ hero, fillScreen, children, ...rest }) => {
  return (
    <Box
      py={["65px", null, "75px", "100px"]}
      {...rest}
      css={
        hero || fillScreen
          ? "min-height: 95vh; display: grid; align-items: center; position: relative;"
          : "min-height: 50vh; position: relative;"
      }
    >
      {children}
    </Box>
  );
};

export default Section;
