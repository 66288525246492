import { createGlobalStyle } from "styled-components";

const globalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.bg} !important;
    transition: background-color .3s ease-in-out;
    color: ${({ theme }) => theme.colors.text} !important;
    font-family: "Poppins", sans serif;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.heading};
  }

  p, .p, ul li {
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    font-weight: 200;
    letter-spacing: -0.56px;
    line-height: 30px;
    margin-bottom: 0;
    font-family: "Poppins", sans serif;
  }

  a {
    transition: all 0.3s ease-out;
    color: ${({ theme }) => theme.colors.heading} !important;

    &:hover, &:active, &:focus, &:focus-visible {
      text-decoration: none !important;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  button {
    background-color: inherit;
    border: none;
  }
  
  button, a {
    transition: outline-offset 0s;
  }

  .nav-link:focus-visible {
    transition: outline-offset 0s !important;
    outline-offset: -2px !important;
    box-shadow: 0 0 0 1px white !important;  
    outline: 2px solid -webkit-focus-ring-color !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  ::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: ${({ theme }) => theme.colors.primary};
  }

  ::selection {
    color: #fff;
    background: ${({ theme }) => theme.colors.primary};
  }

  .visually-hidden {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    margin: -1px !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }

  .visually-hidden-focusable:focus,
  .visually-hidden-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  }
`;

export default globalStyle;
