import styled from "styled-components";
import { Form } from "antd";
import { device } from "../../utils";

const FormStyled = styled(Form)`
  .ant-input,
  .ant-input-affix-wrapper {
    background-color: ${({ theme }) => theme.colors.dark} !important;
    box-shadow: none !important;
    border: 1px solid ${({ theme }) => theme.colors.lightShade};
    font-size: 14px !important;
    color: ${({ theme }) => theme.colors.light};
    border-radius: 0;

    &:hover {
      border-color: ${({ theme }) => theme.colors.light};
      box-shadow: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.lightShade};
    }
  }

  .ant-input-affix-wrapper:focus-within,
  .ant-picker:focus-within,
  .ant-checkbox-input:focus-visible {
    outline: -webkit-focus-ring-color auto 1px !important;
    outline-offset: 2px;
    transition: outline-offset 0s;
  }

  .ant-form-item-label {
    padding-bottom: 4px;

    & label {
      color: ${({ theme }) => theme.colors.lightShade};
      font-size: 16px;
      -webkit-user-select: none;
      user-select: none;

      &.ant-form-item-required::after {
        content: ${({ requiredText }) => (requiredText ? `"(${requiredText})"` : "*")};
        color: #ff4d4f;
        margin-left: 5px;
        z-index: 1;
        visibility: visible;
      }
    }
  }

  .ant-picker-focused,
  .ant-picker:hover {
    border-color: ${({ theme }) => theme.colors.primaryLight} !important;
    box-shadow: none;
  }

  .ant-picker-disabled {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .ant-picker-input > input {
    font-size: 2.5rem;

    @media ${device.maxSm} {
      font-size: 1.2rem;
    }
  }

  .ant-picker-ok .ant-btn-sm {
    height: auto !important;
    padding: 4px 15px !important;
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox + span {
    font-size: 16px;
  }

  .ant-checkbox-wrapper {
    color: ${({ theme }) => theme.colors.light};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${({ theme }) => theme.colors.secondary} !important;
  }

  button:disabled {
    cursor: not-allowed;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.light};
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export default FormStyled;
