import { APPLY_JOB_ENDPOINT, BOOK_MEETING_ENDPOINT, CONTACT_ENDPOINT } from "../../constants/UrlConstants";

export const sendContactMail = (fields) => {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_MAIL + CONTACT_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fields),
    })
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
};

export const sendBookMeetingMail = (fields) => {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_MAIL + BOOK_MEETING_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fields),
    })
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
};

export const sendJobApplicationMail = (fields) => {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_MAIL + APPLY_JOB_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fields),
    })
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
};
