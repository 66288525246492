import { rgba } from "polished";
import { breakpoints } from "./breakpoints";

const defaultColors = {
  blue: "#092DC6",
  red: "#ff4d4f",
  yellow: "#EF9325",
  purple: "#4F1D7B",
  pink: "#D21EE0",
  white: "#ffffff",
  dark: "#0F1F2D",
  ash: "#413e65",
  grey: "#808080",
  black: "#000000",
  error: "#ff4d4f",
  warning: "#fedc5a",
  success: "#56b381",
  info: "#482EC3",
  frostyBlue: "#ACFDFE",
  marinerBlue: "#2768BB",
  easternBlue: "#24A4B0",
  easternBlueDark: "#197f88",
  blackPearl: "#001927",
};

const colors = {
  primary: defaultColors.easternBlue,
  primaryLight: rgba(defaultColors.easternBlue, 0.6),
  primaryDark: defaultColors.easternBlueDark,
  secondary: defaultColors.marinerBlue,
  secondaryLight: rgba(defaultColors.marinerBlue, 0.6),
  tertiary: defaultColors.frostyBlue,
  tertiaryLight: rgba(defaultColors.frostyBlue, 0.6),
  quaternary: defaultColors.easternBlue,
  quaternaryLight: rgba(defaultColors.easternBlue, 0.6),
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.5),
  dark: defaultColors.blackPearl,
  darkShade: rgba(defaultColors.blackPearl, 0.5),
  ash: defaultColors.ash,
  bg: defaultColors.white,
  bgShade: rgba(defaultColors.white, 0.5),
  front: defaultColors.blackPearl,
  frontShade: rgba(defaultColors.blackPearl, 0.5),
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: rgba(defaultColors.blackPearl, 0.85),
  text: rgba(defaultColors.blackPearl, 0.75),
  lightText: rgba(defaultColors.blackPearl, 0.45),
  error: defaultColors.error,
  warning: defaultColors.warning,
  success: defaultColors.success,
  info: defaultColors.info,
  cardShadow: rgba(defaultColors.grey, 0.4),
  imageShadow: rgba(defaultColors.black, 0.45),
  link: defaultColors.marinerBlue,
  modes: {
    dark: {
      primary: defaultColors.easternBlue,
      primaryLight: rgba(defaultColors.easternBlue, 0.6),
      primaryDark: defaultColors.easternBlueDark,
      secondary: defaultColors.marinerBlue,
      secondaryLight: rgba(defaultColors.marinerBlue, 0.6),
      tertiary: defaultColors.frostyBlue,
      tertiaryLight: rgba(defaultColors.frostyBlue, 0.6),
      quaternary: defaultColors.easternBlue,
      quaternaryLight: rgba(defaultColors.easternBlue, 0.6),
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.5),
      dark: defaultColors.blackPearl,
      darkShade: rgba(defaultColors.blackPearl, 0.5),
      ash: defaultColors.ash,
      bg: defaultColors.blackPearl,
      bgShade: rgba(defaultColors.blackPearl, 0.5),
      front: defaultColors.white,
      frontShade: rgba(defaultColors.white, 0.5),
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.85),
      lightText: rgba(defaultColors.blackPearl, 0.45),
      error: defaultColors.error,
      warning: defaultColors.warning,
      success: defaultColors.success,
      info: defaultColors.info,
      cardShadow: rgba(defaultColors.grey, 0.2),
      imageShadow: rgba(defaultColors.easternBlue, 0.4),
      link: rgba(defaultColors.easternBlue, 0.85),
    },
  },
};

const theme = {
  initialColorModeName: "light",
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [`${breakpoints.sm}px`, `${breakpoints.md}px`, `${breakpoints.lg}px`, `${breakpoints.xl}px`],
};

export default theme;
