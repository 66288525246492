import React from "react";
import styled from "styled-components";
import image from "../../assets/image/svg/Streep.svg";
import { device } from "../../utils";

const UnderlineImage = styled.img`
  max-width: 50vw;

  @media ${device.lg} {
    max-width: 20vw;
  }
`;

const Wrapper = styled.div`
  text-align: center;
`;

const TitleUnderline = () => {
  return (
    <Wrapper>
      <UnderlineImage src={image} alt={""} />
    </Wrapper>
  );
};

export default TitleUnderline;
