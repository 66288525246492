export const COMPANY_NAME = "Digital KISS";
export const COMPANY_SLOGAN = "keep it simple software.";
export const EMAIL = "hallo@digitalkiss.nl";
export const PHONE = "+31 35 20 300 44";
export const WHATSAPP = "+31 6 82432407";
export const STREET = "Gooimeer 3B";
export const CITY = "Naarden";
export const ZIPCODE = "1411DC";
export const KVK = "81756356";
export const SOCIAL_MEDIA = [
  {
    title: "Whatsapp",
    url: "https://wa.me/+31682432407",
  },
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/company/71147729",
  },
  {
    title: "Instagram",
    url: "https://www.instagram.com/digitalkiss.nl/",
  },
];
export const LOGO_SRC =
  "https://firebasestorage.googleapis.com/v0/b/g-commerce-a3cc5.appspot.com/o/digitalkiss%2Flogo.png?alt=media";
export const LOGO_SRC_SQUARE =
  "https://firebasestorage.googleapis.com/v0/b/g-commerce-a3cc5.appspot.com/o/digitalkiss%2Flogo-dark-512x512.png?alt=media";
