import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { theme } from "../../utils";

const AnimatedLink = ({ children, ...rest }) => {
  return (
    <AniLink cover duration={0.5} bg={theme.colors.primary} {...rest}>
      {children}
    </AniLink>
  );
};

export default AnimatedLink;
