import React, { useContext } from "react";
import styled from "styled-components";
import { color, shadow, space, typography } from "styled-system";
import { device } from "../../utils";
import TitleUnderline from "./TitleUnderline";
import { transparentize } from "../../utils/helperFn";
import { Parallax } from "react-scroll-parallax";
import GlobalContext from "../../context/GlobalContext";

const SectionTitle = styled.h2`
  font-weight: 600;
  letter-spacing: -1px;
  font-size: clamp(36px, 4vw, 3.5rem);
  line-height: 4vw;
  margin-bottom: 0.5rem;

  @media ${device.maxLg} {
    line-height: 1.3;
  }
  @media ${device.maxSm} {
    line-height: 40px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const SectionSm = styled(SectionTitle)`
  letter-spacing: -1.15px;
  font-size: 2.5rem;
  line-height: 3vw;
  font-weight: 500;

  @media ${device.maxLg} {
    font-size: 2.3rem;
    line-height: 1.25;
  }
  @media ${device.maxSm} {
    font-size: 32px;
    line-height: 32px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const HeroTitle = styled.h1`
  font-weight: 600;
  font-size: max(40px, 5vw);
  line-height: normal;
  margin-bottom: 8px;

  @media ${device.maxXl} {
    line-height: 1.3;
  }

  @media ${device.maxSm} {
    line-height: 54px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const HeroSubPage = styled(HeroTitle)`
  font-size: clamp(2.5rem, 5vw, 4rem);
  line-height: normal;
`;

const HeroSubTitle = styled(SectionTitle)`
  letter-spacing: -2.81px;
  font-size: 33.33px;
  line-height: 56px;
  text-shadow:
    0 6px 8px rgb(0 0 0 / 10%),
    0 9px 10px rgb(0 0 0 / 5%),
    0 30px 10px rgb(0 0 0 / 5%),
    0 15px 10px rgb(0 0 0 / 2%);

  @media ${device.sm} {
    font-size: 44px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 50.66px;
    line-height: 84px;
  }

  @media ${device.xl} {
    font-size: 53.33px;
    line-height: 84px;
  }
`;

const CardTitle = styled.h2`
  font-size: 1.3125rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const CardTitleSm = styled(CardTitle)`
  font-size: 1.0625rem;
  letter-spacing: -0.2px;
  line-height: 1;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const CardTitleLg = styled(CardTitle)`
  font-size: 2.2rem;
  line-height: 1.35;
  @media ${device.maxLg} {
    font-size: 1.8rem;
    line-height: 1.25;
  }
  @media ${device.maxSm} {
    font-size: 24px;
    line-height: 28px;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const CardTitleLgH3 = styled.h3`
  font-size: 2.2rem;
  line-height: 1.35;

  @media only screen and ${device.maxLg} {
    font-size: 1.8rem;
    line-height: 1.25;
  }
  @media only screen and ${device.maxSm} {
    font-size: 24px;
    line-height: 28px;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const CardTitleBig = styled(CardTitle)`
  font-size: clamp(2rem, 3vw, 2.8rem);

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const CardTitleBigH3 = styled.h3`
  letter-spacing: 0.5px;
  line-height: 1;
  font-size: clamp(2rem, 3vw, 2.8rem);
  @media ${device.maxLg} {
    font-size: 1.5rem;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const HoverAnimation = styled.div`
  position: relative;
  width: fit-content;

  &::before {
    transform: scaleX(0);
    transform-origin: bottom right;
  }

  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: ${({ theme }) => transparentize(theme.colors.tertiary, 0.75)};
    z-index: -1;
    transition: transform 0.3s ease;
  }
`;

const Title = ({ variant, underline = false, hoverEffect = false, ...rest }) => {
  const gContext = useContext(GlobalContext);
  const userPrefersReducedMotion = gContext.prefersReducedMotion;

  let TitleStyled;

  switch (variant) {
    case "card":
      TitleStyled = CardTitle;
      break;
    case "cardSm":
      TitleStyled = CardTitleSm;
      break;
    case "cardLg":
      TitleStyled = CardTitleLg;
      break;
    case "cardLgH3":
      TitleStyled = CardTitleLgH3;
      break;
    case "cardBig":
      TitleStyled = CardTitleBig;
      break;
    case "cardBigH3":
      TitleStyled = CardTitleBigH3;
      break;
    case "hero":
      TitleStyled = HeroTitle;
      break;
    case "hero-subpage":
      TitleStyled = HeroSubPage;
      break;
    case "hero-subtitle":
      TitleStyled = HeroSubTitle;
      break;
    case "secSm":
      TitleStyled = SectionSm;
      break;
    default:
      TitleStyled = SectionTitle;
  }

  return (
    <>
      {hoverEffect ? (
        <HoverAnimation>
          <TitleStyled color="heading" {...rest} />
        </HoverAnimation>
      ) : (
        <TitleStyled color="heading" {...rest} />
      )}
      {underline && (
        <>
          <Parallax scaleX={userPrefersReducedMotion ? 1.5 : [0, 1.5, "easeInQuad"]}>
            <TitleUnderline />
          </Parallax>
        </>
      )}
    </>
  );
};

export default Title;
