import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ListGroup } from "react-bootstrap";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import GlobalContext from "../../context/GlobalContext";
import AnimatedLink from "../Core/AnimatedLink";
import { book_meeting } from "../../utils/urls";
import { saveEventToAnalytics } from "../../functions/GooglePlatform/GoogleAnalyticsEvents";
import { PRESS_CTA_BTN } from "../../constants/GoogleAnalyticsConstants";
import { Button } from "../Core";
import { useTranslation } from "react-i18next";

const NestedMenuContainer = styled.div`
  a {
    color: ${({ theme }) => theme.colors.front} !important;
    transition: all 0.3s ease-out;
    font-weight: 700;
    text-transform: lowercase;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }
  }

  .list-group-item {
    font-size: 20px;
    font-weight: 700;
    text-transform: lowercase;
    color: ${({ theme }) => theme.colors.dark};

    &:hover,
    &:active,
    &.active {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
      background-color: transparent;
    }
`;

const defaultMenuItems = [{ name: "home", label: "Home" }];

const MenuItem = ({ label, isExternal = false, name, items, depthStep = 20, depth = 0, ...rest }) => {
  const [open, setOpen] = useState(false);
  const hasSubItems = Array.isArray(items);

  const gContext = useContext(GlobalContext);
  return (
    <>
      {hasSubItems ? (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            padding-right: 0 !important;
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.bg} !important;
          `}
          onClick={() => setOpen(!open)}
          className={`d-flex align-items-center justify-content-between ${open ? "active" : ""}`}
        >
          <span>{label}</span>
          <span>{open ? <FaAngleDown /> : <FaAngleRight />}</span>
        </ListGroup.Item>
      ) : (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            padding-right: 0 !important;
            background-color: ${({ theme }) => theme.colors.bg} !important;
            ${gContext.theme.headerDark ? `border-color: rgb(255 255 255 / 13%) !important` : null}
          `}
        >
          {isExternal ? (
            <a
              href={`${name}`}
              onClick={() => {
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas();
                }
              }}
            >
              {label}
            </a>
          ) : (
            <AnimatedLink
              to={`/${name}`}
              onClick={() => {
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas();
                }
              }}
            >
              {label}
            </AnimatedLink>
          )}
        </ListGroup.Item>
      )}

      {hasSubItems ? (
        <div>
          {items.map((subItem) => (
            <MenuItem key={subItem.name} depth={depth + 1} depthStep={depthStep} {...subItem} />
          ))}
        </div>
      ) : null}
    </>
  );
};

const NestedMenu = ({ menuItems = defaultMenuItems }) => {
  const gContext = useContext(GlobalContext);
  const { t } = useTranslation();
  return (
    <NestedMenuContainer>
      <ListGroup variant="flush">
        {menuItems.map((menuItem, index) => (
          <MenuItem key={`${menuItem.name}${index}`} depthStep={20} depth={0} {...menuItem} />
        ))}
        <div
          css={`
            padding: 1rem 0;
          `}
        >
          <AnimatedLink
            to={book_meeting}
            onClick={(e) => {
              e.preventDefault();
              gContext.toggleOffCanvas();
              saveEventToAnalytics({
                eventName: PRESS_CTA_BTN,
                parameters: { location: "mobile_navbar" },
              });
            }}
          >
            <Button
              css={`
                font-size: 15px;
                padding: 6px 14px;
                border-radius: 2px;

                &:hover,
                &:focus,
                &:active {
                  transform: translateY(0);
                }
              `}
            >
              {t("video_call_cta")}
            </Button>
          </AnimatedLink>
        </div>
      </ListGroup>
    </NestedMenuContainer>
  );
};

export default NestedMenu;
