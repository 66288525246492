import i18n from "../../translations/i18n";

export const menuItems = [
  {
    name: "",
    label: i18n.t("home") + ".",
  },
  {
    name: "over",
    label: i18n.t("about_us") + ".",
  },
  {
    name: "services",
    label: i18n.t("services") + ".",
  },
  {
    name: "cases",
    label: i18n.t("cases") + ".",
  },
  {
    name: "werken-bij",
    label: i18n.t("work_at") + ".",
  },
  {
    name: "blog",
    label: i18n.t("blog") + ".",
  },
  {
    name: "contact",
    label: i18n.t("contact") + ".",
  },
];
