import theme from "./theme";

export const titleCase = (str) => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export function transparentize(color, opacity) {
  let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export const hexToRgb = (hex) => {
  return hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
};

/**
 * Function to determine whether black or white works better on given color
 * */
export const setContrast = (rgb) =>
  (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000 > 125 ? theme.colors.text : "white";

const isBrowser = () => typeof window !== "undefined";
export const isMobile = isBrowser() && window.innerWidth <= 768;

export const getAnchorTagId = (title) => {
  return title
    .replace(/[#,.?]/g, "")
    .replaceAll(" ", "-")
    .toLowerCase();
};
