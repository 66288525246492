import React from "react";
import styled from "styled-components";
import { color, shadow, space, typography } from "styled-system";
import { device } from "../../utils";

const Paragraph = styled.p`
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: -0.2px;
  line-height: 1.71;
  @media ${device.maxLg} {
    font-size: 1.4rem;
  }
  @media ${device.maxSm} {
    font-size: 1.15rem;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const ParagraphLg = styled(Paragraph)`
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 1.6;
  @media ${device.maxLg} {
    font-size: 1.75rem;
  }
  @media ${device.maxSm} {
    font-size: 1.25rem;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const ParagraphSmall = styled(Paragraph)`
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const ParagraphTag = styled(Paragraph)`
  font-size: 2rem;
  line-height: 1.63;
  letter-spacing: 1.63px;
  font-weight: 700;
  @media ${device.maxLg} {
    font-size: 1.85rem;
  }
  @media ${device.maxSm} {
    font-size: 1.35rem;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const ParagraphHero = styled(Paragraph)`
  font-size: 2vw;
  font-weight: 400;
  letter-spacing: -0.66px;
  line-height: normal;
  @media ${device.maxXl} {
    font-size: 30px;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const Text = ({ variant = "lg", ...props }) => {
  let TextRender;

  switch (variant) {
    case "lg":
      TextRender = ParagraphLg;
      break;
    case "small":
      TextRender = ParagraphSmall;
      break;
    case "tag":
      TextRender = ParagraphTag;
      break;
    case "p":
      TextRender = Paragraph;
      break;
    case "hero":
      TextRender = ParagraphHero;
      break;
    default:
      TextRender = ParagraphLg;
  }

  return <TextRender color="text" {...props} />;
};

export default Text;
