import React, { useContext } from "react";
import styled from "styled-components";
import { background, border, color, flexbox, layout, shadow, space, typography } from "styled-system";
import { device } from "../../utils";
import GlobalContext from "../../context/GlobalContext";
import theme from "../../utils/theme";

const ButtonStyled = styled.button`
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1.63px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 23px 30px;
  box-shadow: 0 32px 54px rgba(3, 3, 3, 0.12);
  border-radius: 50rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
  border: none;
  white-space: nowrap;
  outline-offset: 4px;
  ${color};
  ${background};
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
  transition: 0.4s ease-out;

  @media (prefers-contrast: more) {
    background-color: ${theme.colors.primaryDark};
  }

  @media ${device.maxXl} {
    font-size: 13px;
  }
  @media ${device.maxSm} {
    padding: 15px 20px;
  }

  &:hover,
  &:focus,
  &:active {
    transform: translateY(-8px);
    box-shadow: 0 32px 54px rgba(3, 3, 3, 0.14);
  }

  &.loading {
    background-color: ${({ theme }) => theme.colors.frontShade};
  }

  .loading-spinner {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 1.5rem;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      border-radius: 50%;
      animation: rotate-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }

    @keyframes rotate-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const Button = ({ variant = "primary", color = "#fff", arrowRight = false, isLoading, children, ...rest }) => {
  const gContext = useContext(GlobalContext);
  const isDark = gContext.theme.bodyDark;
  return (
    <ButtonStyled
      variant={variant}
      color={color}
      bg={variant}
      disabled={isLoading}
      className={isLoading ? "loading" : ""}
      as={rest.as ? rest.as : "button"}
      {...rest}
    >
      {children}
      {isLoading && (
        <div className="loading-spinner">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {arrowRight && !isLoading && (
        <svg
          id="SVGDoc"
          width="18"
          height="12"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 18 12"
          css={`
            margin-left: 1.5rem;
          `}
        >
          <g>
            <g>
              <g>
                <path
                  d="M1,5.5h16"
                  fillOpacity="0"
                  fill="#ffffff"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeOpacity="1"
                  stroke={isDark ? "#fff" : "#000"}
                  strokeMiterlimit="20"
                  strokeWidth="2"
                ></path>
              </g>
              <g>
                <path
                  d="M12,1v0l5,5v0l-5,5v0"
                  fillOpacity="0"
                  fill="#ffffff"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeOpacity="1"
                  stroke={isDark ? "#fff" : "#000"}
                  strokeMiterlimit="20"
                  strokeWidth="2"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      )}
    </ButtonStyled>
  );
};

export default Button;
