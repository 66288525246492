import React from "react";
import { rgba } from "polished";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Section, Span, Text, Title } from "../../components/Core";
import { CITY, COMPANY_NAME, EMAIL, KVK, PHONE, STREET, WHATSAPP } from "../../constants/CompanyConstants";
import { useTranslation } from "react-i18next";
import ContactForm from "../../components/Forms/ContactForm";
import { device } from "../../utils";

const ContactCard = styled.address`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  margin-top: 3rem;
  box-shadow: ${({ theme }) => `0 20px 40px ${rgba(theme.colors.secondary, 0.1)}`};

  @media ${device.lg} {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.light};

    &.company-name,
    &.company-detail {
      font-weight: 700;
      font-family: "Rubik", sans-serif;
      line-height: 1.5;
    }

    &.company-name {
      font-size: 21px;
      margin-bottom: 0.5rem;
    }

    &.company-detail {
      font-size: 17px;
    }
  }
`;

const Contact = ({ hero = true, bg = "dark", ...rest }) => {
  const { t } = useTranslation();
  return (
    <>
      <Section hero={hero} bg={bg} {...rest} as={"div"}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="6">
              <Box className="pr-lg-5">
                <Title as="h1" color="light" variant="secSm" mb="1rem">
                  {t("contact_title")}
                </Title>
                <Text variant="p" color="light">
                  {t("contact_description")}
                </Text>
                <Text
                  color="light"
                  mb="2.75rem"
                  css={`
                    font-size: 11px;
                    line-height: normal;
                    letter-spacing: normal;
                  `}
                >
                  {t("contact_fine_print")}
                </Text>
                <ContactForm theme="dark" />
              </Box>
            </Col>
            <Col lg="5" className={"align-self-center"}>
              <ContactCard className="p-5 ml-lg-5">
                <div className={"mb-4"}>
                  <h2 className="visually-hidden">{t("contact_details")}</h2>
                  <p className={"company-name"}>{COMPANY_NAME}</p>
                  <p className={"company-detail"}>{STREET}</p>
                  <p className={"company-detail"}>{CITY}</p>
                  <p className={"company-detail"}>
                    {" "}
                    {t("kvk")}: {KVK}
                  </p>
                </div>
                <div>
                  <Text color="light">{t("mail_to")}</Text>
                  <a href={`mailto:${EMAIL}`} className="font-weight-bold" aria-label={`${t("mail_to")} ${EMAIL}`}>
                    <Span color="primary">{EMAIL}</Span>
                  </a>
                </div>
                <div className="mt-3">
                  <Text color="light">{t("call_us")}</Text>
                  <div>
                    <a href={`tel:${PHONE}`} className="font-weight-bold" aria-label={`${t("call_us")} ${PHONE}`}>
                      <Span color="primary">{PHONE}</Span>
                    </a>
                  </div>
                </div>
                <div className="mt-3">
                  <Text color="light">{t("send_a_whatsapp")}</Text>
                  <div>
                    <a
                      href={`https://wa.me/${WHATSAPP}`}
                      className="font-weight-bold"
                      target={"_blank"}
                      aria-label={`${t("send_a_whatsapp_to")} ${WHATSAPP}`}
                    >
                      <Span color="primary">{WHATSAPP}</Span>
                    </a>
                  </div>
                </div>
              </ContactCard>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
