import React from "react";
import AnimatedLink from "../Core/AnimatedLink";
import logo from "../../assets/image/logo/logo-1.webp";
import logoLight from "../../assets/image/logo/logo-1-light.webp";
import logoSm from "../../assets/image/logo/logo-small.png";
import { home } from "../../utils/urls";

const Logo = ({ isDark, small, height, className = "", ...rest }) => {
  let img_src = isDark ? logoLight : logo;
  if (small) img_src = logoSm;
  return (
    <AnimatedLink to={home} className={`${className}`} {...rest}>
      <img
        src={img_src}
        css={`
          object-fit: scale-down;
          max-width: 200px;
          max-height: 60px;
        `}
        alt={"Digital Kiss home"}
      />
    </AnimatedLink>
  );
};

export default Logo;
