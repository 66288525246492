import styled from "styled-components";
import theme from "../../utils/theme";
import React from "react";
import { useTranslation } from "react-i18next";

export default function FormErrorWrapper({ errorFields }) {
  const { t } = useTranslation();
  return (
    <ErrorWrapper role={"alert"} className={errorFields.length > 0 ? "" : "visually-hidden"}>
      <p>
        {errorFields.length > 0 ? (
          errorFields.length > 1 ? (
            <>{t("form.validation_error", { amount: errorFields.length })}</>
          ) : (
            <>{t("form.validation_error_single")}</>
          )
        ) : null}
      </p>
      <ol aria-label={t("form.validation_list")}>
        {errorFields.map((field, index) => (
          <li key={index}>
            <a tabIndex={0} onClick={() => document.getElementById(field.name[0]).focus()}>
              {t(field.name[0])}
              {" - "} {field.errors[0]}
            </a>
          </li>
        ))}
      </ol>
    </ErrorWrapper>
  );
}

const ErrorWrapper = styled.div`
  padding: 1rem;
  border: 1px solid ${theme.colors.error};
  color: ${theme.colors.error};
  margin-bottom: 1rem;
  font-size: 12px;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: inherit;
  }

  ol {
    margin: 0;
  }

  a {
    color: ${theme.colors.error} !important;
    text-decoration: underline !important;
    text-align: left;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px !important;
      outline-offset: 2px;
      transition: outline-offset 0s;
    }

    &:hover {
      opacity: 0.8;
    }

    &:hover,
    &:focus-visible {
      color: ${theme.colors.error} !important;
      text-decoration: none !important;
    }
  }
`;
