import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import NL from "./nl.json";

const fallbackLng = ["nl"];
const availableLanguages = ["nl"];

const resources = {
  nl: {
    translation: NL
  }
};

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng,
    keySeparator: ".",

    debug: false,

    whitelist: availableLanguages,
    detection: {
      order: ["localStorage"],
      lookupFromPathIndex: 0,
      lookUpLocalStorage: "i18nextLng",
      checkWhitelist: true
    },

    interpolation: {
      escapeValue: false // no need for react. it escapes by default
    }
  });

export default i18n;
