import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import GlobalContext from "../../context/GlobalContext";
import { Text } from "../Core";
import { COMPANY_SLOGAN } from "../../constants/CompanyConstants";

const Drawer = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100vw;
  top: 60px;
  right: 0;
  z-index: 1337;
  bottom: 0;
  height: 100%;
  background: ${({ theme }) => theme.colors.bg} !important;
  overflow-y: auto;
  transition: opacity 0.3s, visibility 0.3s;
  transform: translateX(0);

  .dropdown-menu {
    position: static !important;
    will-change: initial !important;
    transform: initial !important;
    float: none !important;
  }
`;

const SloganWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 72px;
  justify-content: center;
`;

const MobileDrawer = ({ show, onHideOffcanvas, children, ...rest }) => {
  const gContext = useContext(GlobalContext);

  if (typeof document !== "undefined") {
    if (show) {
      document.querySelector("html").classList.add("has-offcanvas");
      document.body.classList.add("has-offcanvas");
    } else {
      document.querySelector("html").classList.remove("has-offcanvas");
      document.body.classList.remove("has-offcanvas");
    }
  }

  return (
    <div {...rest}>
      <Drawer
        style={{ opacity: show ? 1 : 0, visibility: show ? "visible" : "hidden" }}
        visible={gContext.visibleOffCanvas}
      >
        <Container>
          <div className="p-3">
            <div className="pt-4">{children}</div>
          </div>
        </Container>
        <SloganWrapper>
          <Text variant={"small"}>{COMPANY_SLOGAN}</Text>
        </SloganWrapper>
      </Drawer>
    </div>
  );
};

export default MobileDrawer;
