module.exports = {
  home: "/",
  about: "/over",
  services: "/services",
  cases: "/cases",
  contact: "/contact",
  blog: "/blog",
  not_found: "/404",
  book_meeting: "/plan-een-gesprek",
  workAt: "/werken-bij",
  applyForJob: "/solliciteren",
  case_detail: "/case",
  service_detail: "/service",
};
