import React from "react";
import styled from "styled-components";
import { FaFacebookF, FaGithub, FaInstagram, FaLinkedinIn, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const icons = {
  Facebook: <FaFacebookF aria-hidden={true} />,
  Twitter: <FaTwitter aria-hidden={true} />,
  Instagram: <FaInstagram aria-hidden={true} />,
  LinkedIn: <FaLinkedinIn aria-hidden={true} />,
  Github: <FaGithub aria-hidden={true} />,
  Whatsapp: <FaWhatsapp aria-hidden={true} />,
};

const SocialContainer = styled.nav`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  svg {
    transition: all 0.3s;
    width: 24px;
    height: 24px;
  }
`;

const Social = ({ items, outline, inverse, className, ...rest }) => {
  const { t } = useTranslation();
  return (
    <SocialContainer className={`d-flex ${className}`} {...rest} aria-label={t("social_media_links")}>
      <ul>
        {items.map((item) => (
          <li key={item.title}>
            <a href={item.url} target="_blank" rel="noopener noreferrer" aria-label={item.title}>
              {icons[item.title]}
            </a>
          </li>
        ))}
      </ul>
    </SocialContainer>
  );
};

export default Social;
