import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import GlobalContext from "../../context/GlobalContext";
import MobileDrawer from "../MobileDrawer";
import NestedMenu from "../NestedMenu";
import { device } from "../../utils";
import Logo from "../Logo";
import { menuItems } from "./menuItems";
import AnimatedLink from "../Core/AnimatedLink";
import { saveEventToAnalytics } from "../../functions/GooglePlatform/GoogleAnalyticsEvents";
import { PRESS_CTA_BTN } from "../../constants/GoogleAnalyticsConstants";
import { book_meeting } from "../../utils/urls";
import { useTranslation } from "react-i18next";
import singleLineImage from "../../assets/image/svg/single-line.svg";
import theme from "../../utils/theme.js";

const SiteHeader = styled.header`
  padding: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  transition: all 0.3s ease-in-out;
  background: ${({ dark, theme }) => theme.colors.bg};

  &.scrolling {
    @media ${device.lg} {
      transform: translateY(-100%);
    }
  }

  &.reveal-header {
    @media ${device.lg} {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
    }
    z-index: 9999;
    background: ${({ theme, customColor }) => (customColor ? customColor : theme.colors.bg)};
  }
`;

const ToggleButton = styled.svg`
  color: ${({ theme }) => theme.colors.front} !important;
  border-color: ${({ theme }) => theme.colors.front} !important;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${({ isActive }) => isActive && `transform: rotate(45deg);`};
  @media (min-width: 992px) {
    display: none;
  }
`;

const Line = styled.path`
  fill: none;
  transition:
    stroke-dasharray 400ms,
    stroke-dashoffset 400ms;
  stroke: ${({ theme }) => theme.colors.front} !important;
  stroke-width: 5.5;
  stroke-linecap: round;

  &.top {
    stroke-dasharray: 40 139;
    ${({ isActive }) => isActive && `stroke-dashoffset: -98px;`}
  }

  &.bottom {
    stroke-dasharray: 40 180;
    ${({ isActive }) => isActive && `stroke-dashoffset: -138px;`}
  }
`;

const Menu = styled.ul`
  @media ${device.lg} {
    display: flex;
    justify-content: flex-end;
  }

  .dropdown-toggle {
    cursor: pointer;
  }

  > li {
    > .nav-link {
      @media ${device.lg} {
        color: ${({ theme }) => theme.colors.front} !important;
        transition: 0.4s;
        align-items: center;
        display: inline-flex;
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        padding-top: 18px !important;
        padding-bottom: 18px !important;
        padding-left: 18px !important;
        padding-right: 18px !important;

        text-transform: lowercase;

        &.dropdown-toggle:after {
          margin-left: 10px;
          position: relative;
          transform: rotate(90deg);
          top: 1px;
          content: "\\ea06";
          border: none;
          font-family: "Grayic";
          font-size: 24px;
          transition: 0.4s;
          font-weight: 900;
          line-height: 14px;
        }
      }
      @media ${device.xl} {
        font-size: 17px;
      }
    }

    &.nav-item:hover > .nav-link,
    &.nav-item.active > .nav-link {
      color: ${({ theme }) => theme.colors.primary} !important;

      @media (prefers-contrast: more) {
        color: ${theme.colors.primaryDark} !important;
      }
      &:after {
        transform: rotate(-90deg);
      }
    }
  }

  .nav-item.dropdown {
    @media ${device.lg} {
      position: relative;
      z-index: 99;
    }

    &:hover {
      > .menu-dropdown {
        @media ${device.lg} {
          top: 90%;
          right: 0;
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
  }
`;

const MenuDropdown = styled.ul`
  list-style: none;
  @media ${device.lg} {
    top: 110%;
    position: absolute;
    min-width: 227px;
    max-width: 227px;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);

    background-color: ${({ dark, theme }) => theme.colors.light};
    padding: 15px 0px;
    z-index: 99;
    opacity: 0;
    transition:
      opacity 0.4s,
      top 0.4s;
    pointer-events: none;
    left: -90%;
    border: 1px solid #e5e5e5;
    border-radius: 0 0 10px 10px;
    display: block;
    border-top: ${({ theme }) => `3px solid ${theme.colors.primary}`};
  }

  > .drop-menu-item {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 15px;
    font-weight: 300;
    letter-spacing: -0.5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
      color: ${({ theme }) => theme.colors.dark} !important;
      transition: all 0.3s ease-out;
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 700;
      text-transform: lowercase;

      &.dropdown-toggle::after {
        margin-left: 10px;
        position: relative;
        top: 1px;
        content: "\\ea06";
        border: none;
        font-family: "Grayic";
        font-size: 24px;
        transition: 0.4s;
        font-weight: 900;
        line-height: 14px;
      }
    }

    &:hover,
    &.active {
      > a {
        color: ${({ theme }) => theme.colors.primary} !important;
        text-decoration: none;

        @media (prefers-contrast: more) {
          color: ${theme.colors.primaryDark} !important;
        }

        &::after {
          transform: rotate(180deg);
        }
      }
    }

    &.dropdown {
      position: relative;

      &:hover {
        > .menu-dropdown {
          @media ${device.lg} {
            top: 10px;
            opacity: 1;
            pointer-events: visible;
            transform: translateX(-100%);
          }
        }
      }

      > .menu-dropdown {
        border-top-color: ${({ theme }) => theme.colors.success};
        @media ${device.lg} {
          top: 10px;
          left: 0%;
          opacity: 0;
          transform: translateX(-110%);
          transition: 0.4s;
          pointer-events: none;
        }

        > .drop-menu-item {
          @media ${device.lg} {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }
  }

  &.dropdown-right {
    left: auto;
    right: -90%;
  }
`;

const StyledAnimatedLink = styled(AnimatedLink)`
  white-space: nowrap;

  &.active {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -0.125rem;
      left: 0.75rem;
      right: 0.75rem;
      height: 1rem;

      z-index: -1;

      background-image: url(${singleLineImage});
      background-repeat: no-repeat;
      background-size: contain;

      animation: fadeInAnimation ease 1.5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;

      @keyframes fadeInAnimation {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    &:hover {
      &::after {
        transform: none !important;
      }
    }
  }
`;

const StyledAnimatedButtonLink = styled(AnimatedLink)`
  padding: 3px 10px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1.63px;
  text-decoration: none;
  color: ${theme.colors.light} !important;
  background-color: ${theme.colors.primary} !important;
  box-shadow: 0 32px 54px rgba(3, 3, 3, 0.12);
  display: block;
  line-height: 30px;
  text-align: center;
  outline-offset: 4px;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    color: ${theme.colors.light} !important;
  }

  @media (prefers-contrast: more) {
    background-color: ${theme.colors.primaryDark} !important;
  }

  @media ${device.maxXl} {
    font-size: 13px;
  }
  @media ${device.maxSm} {
    padding: 15px 20px;
  }
`;

const SkipToMainContentLink = styled.a`
  position: absolute;
  top: -76px;
  left: 0;
  color: ${theme.colors.light} !important;
  background-color: ${theme.colors.dark};
  border-bottom: 1px solid ${theme.colors.light};
  border-right: 1px solid ${theme.colors.light};
  z-index: 1;
  padding: 8px;
  transition: top 0.5s ease-in;
  width: 240px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:focus-visible {
    color: ${theme.colors.light} !important;
    text-decoration: underline !important;
    outline: none !important;
    box-shadow: none;
    top: 0;
    transition: top 0.1s linear;
  }
`;

const Header = ({ isDark }) => {
  const gContext = useContext(GlobalContext);
  const focusRef = useRef(null);
  const { t } = useTranslation();
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);
  const [basePath, setBasePath] = useState("/");
  let pagePath = typeof window !== "undefined" ? window.location.pathname : "/";
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  useEffect(() => {
    const lastSlashIndex = pagePath.split("").findLastIndex((char) => char === "/");
    if (lastSlashIndex > 0) {
      setBasePath(pagePath.slice(0, lastSlashIndex));
    } else setBasePath(pagePath);
  }, [pagePath]);

  useEffect(() => {
    if (focusRef.current) {
      const handleFocus = () => {
        setShowReveal(true);
      };
      const linkElement = focusRef.current.querySelector("a");

      linkElement.addEventListener("focus", handleFocus);
      return () => {
        linkElement.removeEventListener("focus", handleFocus);
      };
    }
  }, [focusRef]);

  const onClickLogo = () => {
    if (window.location.pathname === "/") window.scrollTo(0, 0);
  };

  return (
    <>
      <SiteHeader
        className={`sticky-header ${showScrolling ? "scrolling" : ""} ${showReveal ? "reveal-header" : ""}`}
        customColor={gContext.theme.customColor}
      >
        <SkipToMainContentLink href={"#maincontent"}>{t("skip_to_main_content")}</SkipToMainContentLink>
        <Container fluid>
          <nav className="navbar px-0 px-md-3 site-navbar offcanvas-active navbar-expand-lg navbar-light">
            {/* <!-- Brand Logo--> */}
            <div className="brand-logo">
              <Logo isDark={isDark} onClick={onClickLogo} />
            </div>
            <div className="collapse navbar-collapse">
              <div className="navbar-nav ms-lg-auto mr-3">
                <Menu className="navbar-nav d-none d-lg-flex" dark={isDark ? 1 : 0}>
                  {menuItems.map(({ label, isExternal = false, name, items, ...rest }, index) => {
                    const hasSubItems = Array.isArray(items);
                    return (
                      <React.Fragment key={name + index}>
                        {hasSubItems ? (
                          <li className="nav-item dropdown" {...rest}>
                            <a
                              className="nav-link dropdown-toggle"
                              role="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              href="/#"
                              onClick={(e) => e.preventDefault()}
                            >
                              {label}
                            </a>
                            <MenuDropdown className="menu-dropdown dropdown-right" dark={isDark ? 1 : 0}>
                              {items.map((subItem, indexSub) => {
                                const hasInnerSubItems = Array.isArray(subItem.items);
                                return (
                                  <React.Fragment key={subItem.name + indexSub}>
                                    {hasInnerSubItems ? (
                                      <li className="drop-menu-item dropdown">
                                        <a
                                          className="dropdown-toggle"
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-expanded="false"
                                          href="/#"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          {subItem.label}
                                        </a>
                                        <MenuDropdown className="menu-dropdown dropdown-right" dark={isDark ? 1 : 0}>
                                          {subItem.items.map((itemSubInner, indexSubInner) => {
                                            const hasInnerMostItems = Array.isArray(itemSubInner.items);
                                            return (
                                              <React.Fragment key={itemSubInner.name + indexSubInner}>
                                                {hasInnerMostItems ? (
                                                  <li className="drop-menu-item dropdown">
                                                    <a
                                                      className="dropdown-toggle"
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-expanded="false"
                                                      href="/#"
                                                      onClick={(e) => e.preventDefault()}
                                                    >
                                                      {itemSubInner.label}
                                                    </a>
                                                    <MenuDropdown
                                                      className="menu-dropdown dropdown-right"
                                                      dark={isDark ? 1 : 0}
                                                    >
                                                      {itemSubInner.items.map((itemLast, indexLast) => (
                                                        <li className="drop-menu-item" key={itemLast.name + indexLast}>
                                                          {itemLast.isExternal ? (
                                                            <a
                                                              href={`${itemLast.name}`}
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                            >
                                                              {itemLast.label}
                                                            </a>
                                                          ) : (
                                                            <AnimatedLink to={`/${itemLast.name}`}>
                                                              {itemLast.label}
                                                            </AnimatedLink>
                                                          )}
                                                        </li>
                                                      ))}
                                                    </MenuDropdown>
                                                  </li>
                                                ) : (
                                                  <li className="drop-menu-item">
                                                    {itemSubInner.isExternal ? (
                                                      <a
                                                        href={`${itemSubInner.name}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {itemSubInner.label}
                                                      </a>
                                                    ) : (
                                                      <AnimatedLink to={`/${itemSubInner.name}`}>
                                                        {itemSubInner.label}
                                                      </AnimatedLink>
                                                    )}
                                                  </li>
                                                )}
                                              </React.Fragment>
                                            );
                                          })}
                                        </MenuDropdown>
                                      </li>
                                    ) : (
                                      <li className="drop-menu-item">
                                        {subItem.isExternal ? (
                                          <a href={`${subItem.name}`} target="_blank" rel="noopener noreferrer">
                                            {subItem.label}
                                          </a>
                                        ) : (
                                          <AnimatedLink to={`/${subItem.name}`}>{subItem.label}</AnimatedLink>
                                        )}
                                      </li>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </MenuDropdown>
                          </li>
                        ) : (
                          <li className="nav-item" {...rest}>
                            {isExternal ? (
                              <a className="nav-link" href={`${name}`} target="_blank" rel="noopener noreferrer">
                                {label}
                              </a>
                            ) : (
                              <StyledAnimatedLink
                                className={`nav-link ${
                                  !name
                                    ? pagePath === "/"
                                      ? "active"
                                      : ""
                                    : basePath !== "/" && name.includes(basePath.replace("/", ""))
                                    ? "active"
                                    : ""
                                }`}
                                to={`/${name}`}
                              >
                                {label}
                              </StyledAnimatedLink>
                            )}
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                  <li className="nav-item align-self-center" ref={focusRef}>
                    <StyledAnimatedButtonLink
                      to={book_meeting}
                      onClick={(e) => {
                        e.preventDefault();
                        saveEventToAnalytics({
                          eventName: PRESS_CTA_BTN,
                          parameters: { location: "navbar" },
                        });
                      }}
                    >
                      {t("video_call_cta")}
                    </StyledAnimatedButtonLink>
                  </li>
                </Menu>
              </div>
            </div>

            <ToggleButton
              onClick={gContext.toggleOffCanvas}
              dark={isDark ? 1 : 0}
              viewBox="0 0 100 100"
              width="50"
              isActive={gContext.visibleOffCanvas}
            >
              <Line
                className="top"
                isActive={gContext.visibleOffCanvas}
                d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
              />
              <Line d="m 30,50 h 40" />
              <Line
                className="bottom"
                isActive={gContext.visibleOffCanvas}
                d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
              />
            </ToggleButton>
          </nav>
        </Container>
      </SiteHeader>
      <MobileDrawer show={gContext.visibleOffCanvas} onHideOffcanvas={gContext.toggleOffCanvas}>
        <NestedMenu menuItems={menuItems} />
      </MobileDrawer>
    </>
  );
};
export default Header;
