import styled from "styled-components";
import { background, border, color, flexbox, grid, layout, position, shadow, space, typography } from "styled-system";

const Box = styled.section`
  box-sizing: border-box;
  min-width: 0;
  ${color};
  ${space};
  ${layout};
  ${flexbox};
  ${grid};
  ${typography};
  ${background};
  ${border};
  ${position};
  ${shadow};
`;

export default Box;
