import React, { useState } from "react";
import { Input } from "antd";
import { Button, ButtonIcon, Form } from "../Core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { contactTopics } from "../../data/contact";
import { sendContactMail } from "../../functions/API/contact";
import { saveEventToAnalytics } from "../../functions/GooglePlatform/GoogleAnalyticsEvents";
import { SUBMIT_CONTACT_FORM } from "../../constants/GoogleAnalyticsConstants";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { device } from "../../utils";
import FormErrorWrapper from "../Core/FormErrorWrapper";

const { TextArea } = Input;

const ContactForm = ({ theme = "dark", ...rest }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState(contactTopics[0].key);
  const [errorFields, setErrorFields] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const onPressTopic = (e, key) => {
    e.preventDefault();
    setTopic(key);
  };

  const sendMail = () => {
    form.validateFields().then((fields) => {
      setErrorFields([]);
      fields.topic = contactTopics[topic].item;
      fields.phone = fields.phone || null;
      setIsLoading(true);
      sendContactMail(fields)
        .then((resp) => {
          if (!resp.ok) {
            setShowError(true);
          } else {
            form.resetFields();
            setShowSuccess(true);
          }
          setIsLoading(false);
          saveEventToAnalytics({
            eventName: SUBMIT_CONTACT_FORM,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setShowError(true);
          console.error(err);
        });
    });
  };

  const handleFinishFailed = ({ errorFields }) => {
    setTimeout(() => {
      errorFields.forEach((field) => {
        document.getElementById(`${field.name[0]}_help`).removeAttribute("role");
      });
    }, 100);
    setErrorFields(errorFields);
  };

  return (
    <Form
      form={form}
      onFinish={sendMail}
      onFinishFailed={handleFinishFailed}
      layout="vertical"
      requiredMark={false}
      requiredText={t("required")}
      {...rest}
    >
      <Form.Item name="topic">
        {contactTopics.map((item) => {
          return (
            <TopicButton
              key={item.key}
              onClick={(e) => onPressTopic(e, item.key)}
              active={topic === item.key}
              aria-label={t("contact_topic", { topic: item.label })}
            >
              {item.label}
            </TopicButton>
          );
        })}
      </Form.Item>
      <FormErrorWrapper errorFields={errorFields} />
      <Form.Item
        name="name"
        label={t("first_last_name")}
        hasFeedback
        rules={[
          {
            required: true,
            message: t("form.enter_name"),
          },
        ]}
      >
        <Input size={"large"} />
      </Form.Item>
      <Form.Item name="company" label={t("company")} hasFeedback>
        <Input size={"large"} />
      </Form.Item>
      <Form.Item
        name="email"
        label={t("email")}
        hasFeedback
        rules={[
          {
            required: true,
            message: t("form.enter_email"),
          },
          {
            type: "email",
            message: t("form.enter_valid_email"),
          },
        ]}
      >
        <Input size={"large"} />
      </Form.Item>
      <Form.Item name="phone" label={t("phone_number")} hasFeedback>
        <Input size={"large"} />
      </Form.Item>
      <Form.Item
        hasFeedback
        name="message"
        label={contactTopics[topic].info}
        rules={[
          {
            required: true,
            message: t("form.enter_message"),
          },
        ]}
      >
        <TextArea rows={4} maxLength={220} />
      </Form.Item>
      <ButtonMessageWrapper>
        <Form.Item style={{ margin: 0 }}>
          <ButtonIcon
            icon={"icon-send-2"}
            variant="primary"
            type="submit"
            isLoading={isLoading}
            style={{ fontSize: 14 }}
          >
            {t("send")}
          </ButtonIcon>
        </Form.Item>
        {showSuccess && (
          <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
            <CheckCircleFilled className={"success"} aria-hidden={true} />
            <p role={"alert"} className={"success"}>
              {t("notifications.contact_sent")}
            </p>
          </div>
        )}
        {showError && (
          <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
            <CloseCircleFilled className={"error"} aria-hidden={true} />
            <p role={"alert"} className={"error"}>
              {t("notifications.contact_failed")}
            </p>
          </div>
        )}
      </ButtonMessageWrapper>
    </Form>
  );
};

const TopicButton = styled(Button)`
  padding: 8px 15px;
  margin-right: 8px;
  margin-top: 8px;
  background-color: ${({ theme, active }) => (!active ? "transparent" : theme.colors.secondary)};

  &:hover {
    transform: translateY(-2px);
  }
`;

const ButtonMessageWrapper = styled.div`
  margin-top: 2.5rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  .success {
    color: #52c41a;
  }

  .error {
    color: #ff4d4f;
  }

  @media only screen and ${device.sm} {
    flex-direction: row;
    align-items: center;
  }
`;

export default ContactForm;
