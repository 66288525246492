import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Social from "../Social";
import { COMPANY_SLOGAN, SOCIAL_MEDIA } from "../../constants/CompanyConstants";
import Logo from "../Logo";

const Footer = ({ isDark = true }) => {
  const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 40px 40px;
    font-size: 16px;
  `;

  const FooterItemsWrapper = styled.div`
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  `;

  return (
    <>
      <Footer>
        <Container fluid>
          <FooterItemsWrapper>
            <Logo small onClick={() => window.scrollTo(0, 0)} />
            <CompanySlogan>{COMPANY_SLOGAN}</CompanySlogan>
            <Social items={SOCIAL_MEDIA} />
          </FooterItemsWrapper>
        </Container>
      </Footer>
    </>
  );
};

const CompanySlogan = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
  text-align: center;
  align-self: center;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export default Footer;
